import React, { useEffect, useLayoutEffect, useRef, useState} from 'react'
import MyImg from '../Images/IMG_20221028_151141.jpg'
import '../css/Home.css'

const Home = () => {
  // const thisone = useRef();
  const imgpos = useRef();
  const [flipper, setflipper] = useState(false);
  const [renderedText, setRenderedText] = useState([]);
  const [index, setIndex] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const webdev = ["W", "e", "b", " ", "D", "e", "v", "e", "l", "o", "p", "e", "r"]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const compProg = ["C","o","m","p","e","t","i","t","i","v","e"," ","P","r","o","g","r","a","m","m","e","r"]

  const handleCircles = () => {
    let scroll = window.scrollX;
    let scrollY = window.scrollY;
    let div1 = document.getElementById('an-d-1-t');
    let div2 = document.getElementById('an-d-2-t');
    let div3 = document.getElementById('an-d-1-b');
    let div4 = document.getElementById('an-d-2-b');
    if(scroll === 0){
      div1.style.opacity = 1;
      div2.style.opacity = 1;
      div3.style.opacity = 1;
      div4.style.opacity = 1;
      document.getElementById('outside-my-img').style.transform = `translateX(0px)`;
      document.getElementById('my-img').style.rotate = `0deg`;
    }
    else if(scroll > 0 && scroll <= 620){
      div1.style.opacity = `${20/scroll}`;
      div2.style.opacity = `${20/scroll}`;
      div3.style.opacity = `${20/scroll}`;
      div4.style.opacity = `${20/scroll}`;
      const myimg = document.getElementById('my-img');
      const outsideMyImg = document.getElementById('outside-my-img');
      outsideMyImg.style.transform = `translateX(-${scroll}px)`;  
      scroll > 0 && scroll <= 620 ? myimg.style.rotate = `${scroll}deg` : myimg.style.rotate = `${360}deg`;

    }

    if(document.documentElement.clientWidth <= 768){
        if(scrollY === 0){
          div1.style.opacity = 1;
          div2.style.opacity = 1;
          div3.style.opacity = 1;
          div4.style.opacity = 1;
          document.getElementById('outside-my-img').style.transform = `translateX(0px)`;
          document.getElementById('my-img').style.rotate = `0deg`;
        }
        else if(scrollY > 0 && scrollY <= 620){
          div1.style.opacity = `${20/scrollY}`;
          div2.style.opacity = `${20/scrollY}`;
          div3.style.opacity = `${20/scrollY}`;
          div4.style.opacity = `${20/scrollY}`;
          const myimg = document.getElementById('my-img');
          const outsideMyImg = document.getElementById('outside-my-img');
          outsideMyImg.style.transform = `translateY(-${scrollY}px)`;  
          scrollY > 0 && scrollY <= 620 ? myimg.style.rotate = `${scrollY}deg` : myimg.style.rotate = `${360}deg`;
        }
    }
    const pos = imgpos.current.getBoundingClientRect();
    if(JSON.parse(localStorage.getItem("imgpos")).x === pos.x && JSON.parse(localStorage.getItem("imgpos")).y === pos.y){
      const ele = document.createElement('div')
      ele.id = 'showbubble';
 
      ele.style.left = `${JSON.parse(localStorage.getItem("imgpos")).left}px`;
      ele.style.right = `${JSON.parse(localStorage.getItem("imgpos")).right}px`;
      ele.style.top = `${JSON.parse(localStorage.getItem("imgpos")).top}px`;
      ele.style.bottom = `${JSON.parse(localStorage.getItem("imgpos")).bottom}px`;
      ele.style.width = `${JSON.parse(localStorage.getItem("imgpos")).width}px`;
      ele.style.height = `${JSON.parse(localStorage.getItem("imgpos")).height}px`;
      document.body.appendChild(ele);
    
      setTimeout(() => {
        document.body.removeChild(ele);
      }, 900);
    }
  }

  useLayoutEffect(() => {
    const pos = imgpos.current.getBoundingClientRect();
    localStorage.setItem("imgpos", JSON.stringify(pos));
  },[]);

  useEffect(() => {
    // const observer = new IntersectionObserver((entries) => {
      //   const entry= entries[1];
      //   console.log(entry);
      // });
      // observer.observe(thisone.current);

      const timeoutId = setTimeout(() => {
        if (index < webdev.length && !flipper) {
          setRenderedText((prevRenderedText) => [
            ...prevRenderedText,
            webdev[index],
          ]);
          setIndex((prevIndex) => prevIndex + 1);
        }
        else if(index < compProg.length && flipper){
          setRenderedText((prevRenderedText) => [
            ...prevRenderedText,
            compProg[index],
          ]);
          setIndex((prevIndex) => prevIndex + 1);
        }
      }, 500);

      const intervalId = setInterval(() => {
        setflipper((prevFlipper) => !prevFlipper);
        setIndex(0);
        setRenderedText([]);
      }, 3000);
  
    window.addEventListener('scroll', handleCircles);
  
    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('scroll', handleCircles);
      clearTimeout(timeoutId);
    };
  },[webdev, index,flipper, compProg]);


  return (
    <div className='main-home'>
      <div className="heading-div">
        <div className="img-div">
          <div className="animate-div-2-top" id='an-d-2-t'></div>
          <div className="animate-div-1-top" id='an-d-1-t'></div>
          <div id="outside-my-img">
            <img src={MyImg} alt="Profile Img" id='my-img' ref={imgpos} className='my-img'/>
          </div>
          <div className="animate-div-1-bottom" id='an-d-1-b'></div>
          <div className="animate-div-2-bottom" id='an-d-2-b'></div>
        </div>
        <div className="text-div">
          <p className="head-text">Hi {localStorage.getItem("name") ? localStorage.getItem("name") : "person"}, I'm Akash</p>
          <div className="sub-heading">
            <p>I'm&nbsp;</p>
            <div className="main-sub-head-para">
              {renderedText.map((text, index) => { 
                  return (
                    <pre className='sub-head-para' key={index} style={{fontFamily:"Verdana, Geneva, Tahoma, sans-serif"}}>{text}</pre>
                  )
              })
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
