import React from 'react'
import '../css/Navbar.css'

const Navvbar = () => {
  const HandleTop1 = () => {
    document.getElementById("under-line-top").style.width = "22.5px";
    document.getElementById("under-line-top").style.borderBottom = "1px solid #fff";
  }
  const HandleTop2 = () => {
    document.getElementById("under-line-top").style.width = "0";
    document.getElementById("under-line-top").style.borderBottom = "0";
  }

  const HandleBottom1 = () => {
    document.getElementById("under-line-bottom").style.width = "28.5px";
    document.getElementById("under-line-bottom").style.borderBottom = "1px solid #fff";
  }

  const HandleBottom2 = () => {
    document.getElementById("under-line-bottom").style.width = "0";
    document.getElementById("under-line-bottom").style.borderBottom = "0";
  }

  const handleAbout = () => {
    if(document.documentElement.clientWidth <= 1024){
      window.scrollTo(0, window.scrollY + window.innerHeight)
    }
    else{
      window.scrollTo(window.scrollX + window.innerWidth, 0)
    }
  }


  return (
    <header>
      <nav className='main-nav'>
        <ul className='nav-ul-top'>  
          <li>
            <p onMouseEnter={HandleTop1} onClick={handleAbout} onMouseLeave={HandleTop2}>About</p>
            <div className="under-line-top" id='under-line-top'></div>
          </li> 
        </ul>
        <ul className='nav-ul-bottom'>
          <li>
            <a href="mailto:akashdholakiya222@gmail.com" onMouseOver={HandleBottom1} onMouseOut={HandleBottom2}>Contact</a>
            <div className="under-line-bottom" id='under-line-bottom'></div>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Navvbar
