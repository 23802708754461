import React, { Suspense } from 'react';
import '../css/Project.css'; // Assuming you have a CSS file for styling

const Project = (p) => {

  const data = [
    {
      title: "Prep Tech",
      description: "Prep Tech is based on inteview preparation. It is a platform where you can get the pathway for learning any technology. Also it provides person to person interview and AI interview functionality.",
      image: "/prep-tech.png",
      url: "https://prep-tech-frontend.vercel.app/",
      github: "https://github.com/AkashDholakiya/Prep-Tech"
    },
    {
      title: "Text Editor",
      description: "Text editor is real time text editor where you can write anything and can share it with your friends. It is based on socket programming.",
      image: "/texteditor.png",
      url: "https://texteditor-frontend.vercel.app/",
      github: "https://github.com/AkashDholakiya/texteditor"
    },
    {
      title: "RecipeIt",
      description: "RecipeIt is a recipe sharing platform where you can share your recipes and can see others recipes.",
      image: "/recipe.png",
      url: "https://reciptit.netlify.app/",
      github: "https://github.com/AkashDholakiya/RecipeIt"
    },
    {
      title: "Fitpal",
      description: "FitPal is a fitness tracking web application which can help you in exercise tracking, diet tracking and can provide you the best diet plan and exercise plan.",
      image: "/fitpal.png",
      github: "https://github.com/AkashDholakiya/Devheat_beta_WebWarriors.git"
    }
  ];



  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div id="project" className="container">
        <img src="/icons8-back-arrow-50.png" lazy onClick={() => p.setprojActive(false)} className="back" alt="sample" />
        <h1>My Projects</h1>
        <div className="proj-sub">
          {data.map((project, index) => {
            return (
              <div className="proj-aligns" key={index}>
                <img src={project.image} className='proj-img' lazy="true" alt="sample" />
                <div style={{width:"100%",height:"50%", display:"flex", justifyContent:"space-between", alignItems:"start", flexDirection:"column",  borderRadius:"20px", padding:"20px"}}>
                  <h2>{project.title}</h2>
                  <p style={{marginTop:"10px", marginBottom:"20px", textAlign:"justify"}}>{project.description}</p>
                  <div style={{display: "flex",width:"100%", justifyContent:"space-between"}}>
                    <a href={project.github} target="_blank" rel="noreferrer">View Github</a>
                    {project.url && <a href={project.url} target="_blank" rel="noreferrer">View Project</a>}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div>&nbsp;</div>
      </div>
    </Suspense>
  );
};

export default Project;
