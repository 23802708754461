import React, { useState } from 'react'
import '../css/SplashScreen.css'

const SplashScreen = (p) => {
  const [name, setName] = useState('');
  const [err, seterr] = useState(false);

  const InvalidHandler = () => {
    const bbsubwid = document.getElementById('b-b-sub-wid');
      document.getElementById('name').placeholder = "invalid name";
      bbsubwid.style.borderBottom = '2px solid #f00';
      bbsubwid.style.visibility = 'visible';
      bbsubwid.style.width = '60%';      
      bbsubwid.style.transition = '0.5s';
  }

  const validHandler = () => {
    const bbsubwid = document.getElementById('b-b-sub-wid');
    document.getElementById('name').placeholder = 'Enter your name';
    bbsubwid.style.borderBottom = '2px solid #fff';
    bbsubwid.style.visibility = 'visible';
    bbsubwid.style.width = '60%';
    bbsubwid.style.transition = '0.5s';
  }


  const handleClick = () => {
    const validate = /^[a-zA-Z]{2,9}$/;
    if(!validate.test(name)) {
      setName('');
      InvalidHandler();
      seterr(true);
    }else{
      localStorage.setItem("name", name);
      seterr(false);
      p.setuserinit(name);
    }
  }

  const handleOver = () => {
    const sdiv = document.getElementById('s-div');
    sdiv.style.visibility = 'visible';
  }

  const handleLeave = () => {
    document.getElementById('s-div').style.visibility = 'hidden';
  }

  const handleFocus = () => {
    const bbsubwid = document.getElementById('b-b-sub-wid');
    document.getElementById('name').placeholder = 'Enter your name';  
    bbsubwid.style.borderBottom = '2px solid #fff';
    bbsubwid.style.visibility = 'visible';
    bbsubwid.style.width = '60%';
    bbsubwid.style.transition = '0.5s'; 
    seterr(false);
    const validate = /^[a-zA-Z]{2,9}$/;
    if(validate.test(name)) {
      validHandler();
      seterr(false);
    }
    else{
      InvalidHandler();
      seterr(true);
    }
  }

  const handleBlur = () => {
    const bbsubwid = document.getElementById('b-b-sub-wid');
    if(bbsubwid.style.width === '60%'){
      bbsubwid.style.width = '0';
      document.getElementById('name').placeholder = 'Enter your name';
      bbsubwid.style.visibility = 'hidden'; 
      bbsubwid.style.transition = '0.5s';
      seterr(false);
    }
  }


  const HandleChange = (e) => {
    const validate = /^[a-zA-Z]{2,9}$/;
    if(validate.test(e.target.value)) {
      validHandler();
      seterr(false);
    }
    else{
      InvalidHandler();
      seterr(true);
    }
    setName(e.target.value);
  }
  return (
    <div className='main-splash'>
        <div className="modal">
            <div className="modal-head">
                <h1>Let me know your name</h1>
            </div>
            <div className="modal-body">
                <div className="name-input">
                    <label>Enter your precious name : </label>
                    <input type="text" name="name" id="name" value={name} onChange={HandleChange} placeholder='Enter your name' className={`${err ? 'placeholder-Error' : ''}`}  onFocus={handleFocus} onBlur={handleBlur} onKeyDown={(e) => {if(e.key === 'Enter'){handleClick()}}} autoComplete='off' />
                    <div className="b-b-wid"><span className="b-b-sub-wid" id='b-b-sub-wid'></span></div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="btn-div">
                    <button className='btn' type='submit' onClick={handleClick} onMouseOver={handleOver} onMouseLeave={handleLeave}>Done</button>
                </div>
                <div className="s-div" id='s-div'></div>
            </div>
        </div>
    </div>
  )
}

export default SplashScreen
