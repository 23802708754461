import React, {  useEffect, useState } from 'react';
import './App.css';
import Navvbar from './Components/Navvbar';
import Home from './Routes/Home';
import About from './Routes/About';
import SplashScreen from './Components/SplashScreen';
import Project from './Routes/Project';

function App() {
  const [userinit, setuserinit] = useState('');
  const [projActive, setprojActive] = useState(false);

  const styles = {
    tempstyle: {
      width : document.documentElement.clientWidth <= 768 || userinit === '' || projActive ? "100vw" : "200vw",
    }
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      const cursor = document.getElementById("custom-cursor");
      const app = document.getElementById("app");
    
      // Calculate maximum allowable positions for cursor
      let cursorX = e.pageX;
      let cursorY = e.pageY + 10;
    
      cursor.style.left = cursorX + "px";
      cursor.style.top = cursorY + "px";
    
      const lightningCount = 3;
    
      for (let i = 0; i < lightningCount; i++) {
        const lightning = document.createElement("div");
        lightning.classList.add("lightning");
    
        let randomX = Math.random() * 20;
        let randomY = Math.random() * 20;
    
        let lightningX = cursorX + 16 + randomX;
        let lightningY = cursorY + 16 + randomY;
    
    
        lightning.style.left = lightningX + "px";
        lightning.style.top = lightningY + "px";
    
        app.appendChild(lightning);
    
        // Adjust the animation to ensure it doesn't move the lightning outside the viewport
        lightning.style.animation = `strike 0.5s forwards`;
    
        setTimeout(() => {
          lightning.remove();
        }, 500);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    if(document.documentElement.clientWidth <= 1100){
      document.getElementById("custom-cursor").style.display = "none";
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };

  }, []);



  return (
    <div className="App" id='app' style={styles.tempstyle}>
        <div id="custom-cursor" />
        {userinit === '' && <SplashScreen setuserinit={setuserinit} />}
        {userinit !== '' && !projActive && <Navvbar />}
        {userinit !== '' && !projActive && <Home/>} 
        {userinit !== '' && !projActive && <About setprojActive={setprojActive} />}
        {userinit !== '' && projActive && <Project setprojActive={setprojActive} />}
    </div>
  );
}

export default App;
