import React, { useRef } from 'react';
import '../css/About.css';
import { motion, useInView } from 'framer-motion';

const About = (p) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 0.1 });


  document.handleProj = () => {
    p.setprojActive(true)
  }

  const array = [
    "Hello, Akash!",
    `Hey, ${localStorage.getItem("name") ? localStorage.getItem("name") : "Person"}, nice to meet you!`,
    "Can you tell me something about your studies?",
    "Yes, currently I am pursuing a course in Computer Science and Engineering at the Indian Institute of Information Technology, Surat.",
    "Alright! It's good to hear that. Can you tell me about your technical skills and From where you began learning them?",
    "I began my journey with C++ in the first semester of my college. I completed most of my DSA (Data Structures and Algorithms) coursework in C++. In the second semester, I discovered web development, which intrigued me. Working with HTML, CSS, and JS was fascinating, so I decided to pursue it further. Currently, I am proficient in the complete MERN stack and have developed several projects based on my knowledge. In the third semester, I started learning Python, building on my previous programming experience. Although we had some exposure to Python in the first semester, it was at a very basic level. So, these are my technical skills up to this point.",
    "Okay! So, what are you currently familiar with?",
    "Currently, I am familiar with C++ for competitive programming, Python for Data Science and Machine Learning, and the MERN stack for web development. Additionally, I am experienced in working with SQL, Git, GitHub, and Postman.",
    "Okay, what about your projects?",
    `Alright, for projects, &nbsp; <u onclick='handleProj()'>Click Here</u>.`
  ];
  
  
  return (
    <div id='about' className='main-about'>
      <div className="sub-about" ref={ref}>
        {array.map((ans, key) => (
          <motion.div
            key={key}
            initial={{ x: -120, opacity: 0 }}
            animate={isInView ? {x: 0, opacity: 1, transition : {duration : 1} } : {x: -120, opacity: 0 }}
            whileHover={{ scale: 1.05, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}
            className='conver-div'
            dangerouslySetInnerHTML={{ __html: `${key%2 === 0 ? `${localStorage.getItem("name") ? localStorage.getItem("name") + " : " : "Person : "}` : "Akash : "}` + ans }}
        />
        ))}
      </div>
    </div>
  );
};

export default About;
